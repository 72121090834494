import { User, onAuthStateChanged } from "firebase/auth";
import { getDatabase, onValue, ref, set } from "firebase/database";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { auth, database, onChildAddedOrChanged } from "../firebase/firebase";
import { identify } from "../utils/tracking";
import { useUi } from "./UiContext";

interface SubscriptionInfo {
  active: boolean;
  customerId: string;
  plan: string;
  priceId: string;
  availablePages: number;
  pagesUsed: number;
  billingPortalLink: string;
  paymentMethodId: string;
  availableAnalyses: {
    [key: string]: {
      availablePages: number;
      pagesUsed: number;
      includedAnalyses: string[];
      name?: string;
    };
  };
  tokens?: {
    availableTokens?: number;
    usedTokens?: number;
  };
}

export interface UserSettings {
  displayOptions?: {
    [optionKey: string]: boolean;
  };
  availableOptions?: {
    [optionKey: string]: boolean;
  };
  trimUserRecordingAudio?: boolean;
  audioRecordingOutputPreference?: "user" | "computer" | null;
}

export interface AuthContextType {
  user: User | undefined;
  isSuperAdmin: boolean;
  subscriptionInfo: SubscriptionInfo | null;
  settings?: UserSettings;
  loading: boolean;
  subscriptionInfoField: string;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function useAuth(): AuthContextType {
  return useContext(AuthContext);
}

interface AuthProviderProps {
  children: ReactNode;
}

const fetchSuperAdminStatus = async (uid: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const db = getDatabase();
    const dataRef = ref(db, `superadmin_info/list/${uid}`);

    onValue(
      dataRef,
      (snapshot) => {
        if (snapshot.exists()) {
          resolve(true);
        } else {
          resolve(false);
        }
      },
      (error) => {
        console.error(
          `Error fetching data from superadmin_info/list/${uid}:`,
          error
        );
        reject(error);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

function createDeferredPromise() {
  let resolveFn: Function | undefined, rejectFn: Function | undefined;

  const promise: Promise<User> = new Promise((resolve, reject) => {
    resolveFn = resolve;
    rejectFn = reject;
  });

  return {
    promise,
    resolve: resolveFn,
    reject: rejectFn,
  };
}

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);

  const [subscriptionInfo, setSubscriptionInfo] =
    useState<SubscriptionInfo | null>(null);
  const [settings, setSettings] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // @ts-ignore
      window.user = user;
      setLoading(false);
      setUser(user || undefined);

      if (user) {
        fetchSuperAdminStatus(user.uid).then((status) => {
          setIsSuperAdmin(status);
        });

        identify(user.uid, user.toJSON());
        set(ref(database, `iq_user_list/${user.uid}/lastSignIn`), Date.now());
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user) {
      onValue(
        ref(database, `iq_user_list/${user.uid}/settings`),
        (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setSettings((prevSettings: any) => {
              return data;
            });
          }
        }
      );
    }
  }, [user]);
  const [{ isEnvisionStudio }] = useUi();
  const subscriptionInfoField = isEnvisionStudio
    ? "videoSubscriptionInfo"
    : "subscriptionInfo";
  useEffect(() => {
    if (user) {
      onChildAddedOrChanged(
        ref(database, `iq_user_list/${user.uid}/${subscriptionInfoField}`),
        (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setSubscriptionInfo((prevSubscriptionInfo) => {
              const updatedSubscriptionInfo = {
                ...prevSubscriptionInfo,
                [snapshot.key as keyof SubscriptionInfo]: data,
              } as SubscriptionInfo;

              // Calculate the sums of availablePages and pagesUsed from all the children of availableAnalyses
              if (snapshot.key === "availableAnalyses" && data) {
                const analysesKeys = Object.keys(data);
                const totalAvailablePages = analysesKeys.reduce(
                  (acc, key) => acc + data[key].availablePages,
                  0
                );
                const totalPagesUsed = analysesKeys.reduce(
                  (acc, key) => acc + data[key].pagesUsed,
                  0
                );

                updatedSubscriptionInfo.availablePages = totalAvailablePages;
                updatedSubscriptionInfo.pagesUsed = totalPagesUsed;
              }

              // Set the subscription active if the subscriptionStatus is "active"
              if (snapshot.key === "subscriptionStatus" && data === "active") {
                updatedSubscriptionInfo.active = true;
              }

              return updatedSubscriptionInfo;
            });
          }
        }
      );
    }
  }, [user]);

  const value = {
    user,
    subscriptionInfo,
    subscriptionInfoField,
    settings,
    isSuperAdmin,
    loading,
  };
  // console.log("auth context", value);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
