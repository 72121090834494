import { PlayerRef } from "@remotion/player";
import { MediaPlayerInstance } from "@vidstack/react";
import { useDeferredValue, useMemo, useReducer, useRef } from "react";
import useActiveReport from "../hooks/useActiveReport";
import { Video } from "../types";
import { useDebouncedValue } from "@mantine/hooks";
import { useSubscribeToUserEdits } from "../components/Video/Editor/EditorContext/useUserEdits";
import { performConditionalShallowMerge } from "../utils/misc";
import { assign, merge } from "lodash";

import {
  createContext,
  useContext,
  useContextSelector,
} from "use-context-selector";

interface VideoState {
  currentBackgroundImageKey: string;

  currentSceneId: string;
  isRegeneratingPitchAudio: boolean;
  isRegeneratingPerformanceAudio: boolean;
  isRegeneratingSynopsisAudio: boolean;
  isVideoEditingAssistantOpen: boolean;
}

const initialState: VideoState = {
  currentBackgroundImageKey: "",

  currentSceneId: "",
  isRegeneratingPitchAudio: false,
  isRegeneratingPerformanceAudio: false,
  isRegeneratingSynopsisAudio: false,
  isVideoEditingAssistantOpen: false,
};

type VideoActionType = "setProperty" | "reset";

interface VideoAction {
  type: VideoActionType;
  payload?: any;
}

const actionCreators = {
  reset: (): VideoAction => ({ type: "reset" }),
  setProperty: (property: keyof VideoState, value: any): VideoAction => ({
    type: "setProperty",
    payload: { property, value },
  }),
};

function videoReducer(state: VideoState, action: VideoAction): VideoState {
  switch (action.type) {
    case "setProperty":
      return { ...state, [action.payload.property]: action.payload.value };
    case "reset":
      return initialState;
    default:
      return state;
  }
}

interface VideoContextInterface {
  state: VideoState;
  dispatch: React.Dispatch<VideoAction>;
  remotionPlayerRef?: React.RefObject<PlayerRef>;
  mediaPlayerRef?: React.RefObject<MediaPlayerInstance>;
  video?: Video;
}

const VideoContext = createContext<VideoContextInterface>({
  state: initialState,
  dispatch: () => null,
  remotionPlayerRef: undefined,
  mediaPlayerRef: undefined,
  video: undefined,
});

const VideoProvider: React.FC<{
  path: "performance" | "synopsisVideo" | "pitchVideo";
  subPath?:
    | "tinyDetailedSynopsisVideo"
    | "shortDetailedSynopsisVideo"
    | "mediumDetailedSynopsisVideo"
    | "longDetailedSynopsisVideo";

  children: React.ReactNode;
}> = ({ path, subPath, children }) => {
  const [state, dispatch] = useReducer(videoReducer, initialState);
  const remotionPlayerRef = useRef<PlayerRef>(null);
  const mediaPlayerRef = useRef<MediaPlayerInstance>(null);
  const report = useActiveReport();

  let video = subPath
    ? report?.data?.[path as "synopsisVideo"]?.[subPath]
    : (report?.data?.[path] as Video);

  console.log("videosubPath", path, subPath, video);

  const userEdits = useSubscribeToUserEdits(report?.reportId, path, subPath);

  const mergedVideo = useMemo(() => {
    let newVideo = assign({}, video);

    merge(newVideo, userEdits);
    performConditionalShallowMerge(newVideo, userEdits);
    return newVideo;
  }, [video, userEdits]);

  const deferredVideo = useDeferredValue(mergedVideo);

  return (
    <VideoContext.Provider
      value={{
        state,
        dispatch,
        remotionPlayerRef,
        mediaPlayerRef,
        video: deferredVideo,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

const useVideo = () => {
  const state = useContextSelector(VideoContext, (value) => value.state);
  const dispatch = useContextSelector(VideoContext, (value) => value.dispatch);
  const remotionPlayerRef = useContextSelector(
    VideoContext,
    (value) => value.remotionPlayerRef
  );
  const mediaPlayerRef = useContextSelector(
    VideoContext,
    (value) => value.mediaPlayerRef
  );
  const video = useContextSelector(VideoContext, (value) => value.video);

  const actions = {
    reset: () => dispatch(actionCreators.reset()),
    setProperty: (property: keyof VideoState, value: any) =>
      dispatch(actionCreators.setProperty(property, value)),
  };

  return {
    state,
    actions,
    remotionPlayerRef,
    mediaPlayerRef,
    video,
  };
};

const useVideoMultiMedia = () => {
  const video = useContextSelector(VideoContext, (value) => value.video);
  return video?.multimedia;
};

function usePartialVideo<selected>(
  selector: (value: VideoContextInterface) => selected
): selected {
  return useContextSelector(VideoContext, selector);
}

export { VideoProvider, useVideo, useVideoMultiMedia, usePartialVideo };
