import { Box, Image } from "@mantine/core";
import { CharacterDetails } from "../../../types";

export const getActiveImageId = (
  character?: CharacterDetails,
  location: "headshotUrl" | "imageUrls" = "imageUrls"
) => {
  if (!character) return undefined;
  const characterImages = character?.images?.characterImages || {};
  const availableImages = Object.keys(characterImages);
  const imageId = availableImages.reduce<string | undefined>(
    (maxId, currentId) => {
      const currentImage = characterImages[currentId];
      const currentTimestamp = currentImage.activeImageTimestamp || 0;
      const hasImageUrl =
        currentImage?.[location]?.imageUrl ||
        currentImage?.imageUrls?.imageUrl ||
        currentImage?.headshotUrl?.imageUrl;

      if (
        hasImageUrl &&
        (!maxId ||
          currentTimestamp > (characterImages[maxId].activeImageTimestamp || 0))
      ) {
        return currentId;
      }
      return maxId;
    },
    undefined
  );
  return imageId;
};

export const CharacterImage = (props: { character: CharacterDetails }) => {
  const { character } = props;
  const imageId = getActiveImageId(character);
  const imageUrl = imageId
    ? character?.images?.characterImages?.[imageId]?.imageUrls?.imageUrl
    : character.imageUrls?.imageUrl || character.headshotUrl?.imageUrl;
  return (
    <Box
      // TODO bring w and h back when we have the right image sizes from Imagen2
      // w={{
      //   base: 63,
      //   lg: 172,
      // }}
      // h={{
      //   base: 86,
      //   lg: 236,
      // }}
      // radius="md"
      style={{
        overflow: "hidden",
        //minHeight: 172,
        width: 172,
        //height: "auto",
        height: 172,
        backgroundColor: "red",
        borderRadius: "0.5rem",
      }}
      className=""
      bg="slate.2"
    >
      <Image w="100%" h="100%" src={imageUrl} />
    </Box>
  );
};
